import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import Students from '../../components/students/students';
import { OrgGroupLevel } from '../../common/enums';

const StudentsContainer = () => {
  const { mainOrg } = useSelector((state: RootState) => state.user); //Students tab will always use the main org
  
  useEffect(() => {
    store.dispatch(setPageTitle('Students'));
  }, []);

  const orgDetails = {
    orgGroupName: mainOrg?.name ?? '',
    orgGroupGuid: mainOrg?.orgGuid ?? '',
    orgGroupLevel: mainOrg?.orgType.toLowerCase() as OrgGroupLevel ?? '',
  };

  return <Students {...orgDetails} />;
};

export default StudentsContainer;
