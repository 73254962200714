import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { SearchSubmit, PagedNavTable, MpngExportReport, SearchHelpMessage } from '@wne/mpng-components';
import { Paged } from '../../fetch-services/user.service.types';
import { Class, ClassEducator, ClassesSearchRequest } from '../../fetch-services/group.service.types';
import { GroupService } from '../../fetch-services/group.service';
import { getReportFilename, getEducatorDisplayNames } from '../../common/utils';
import { ClassListProps } from './class-list.props';
import './class-list.scss';
import { Products } from '../../common/enums';

const emptyPage: Paged<Class> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const ClassList = (props: ClassListProps) => {
  const { orgName, orgGuid } = props;
  const [classesPagedData, setClassesPagedData] = React.useState<Paged<Class>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('classname');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  let classColumnDetails =
  [
    {
      name: 'Class Name',
      getColumnData: (data: Class) => data.classTitle,
      alignment: 'left',
      sortProperty: 'classname',
      onLinkSelect: (data: Class) => navigate(`/class/${data.classGUID}`)
    },
    {
      name: 'Teacher',
      getColumnData: (data: Class) => getEducatorDisplayNames(data.educators),
      sortProperty: 'teacher',
      alignment: 'left'
    }
  ];

  const classesCSVColumns = [
    {
      label: 'Class Name',
      id: 'className'
    },
    {
      label: 'Class External ID',
      id: 'districtAssignedId'
    },
    {
      label: 'Teacher Name',
      id: 'teacherName'
    }
  ];

  const getClassesSearchRequest = (): ClassesSearchRequest => {
    const request: ClassesSearchRequest = {
      searchText: searchText,
      sortName: sortColumn,
      sortOrder: sortDirection,
      organizationGuid: orgGuid,
      page: page,
      pageSize: pageSize,
      products: [Products.IPA]
    };
    return request;
  };

  const fetchClasses = async () => {
    store.dispatch(setActivityIndicator(true));
    const response = await GroupService.getClasses(getClassesSearchRequest());
    if (response) {
      setClassesPagedData(response);
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchClassesForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    const response = await GroupService.getClassesCSV(getClassesSearchRequest());
    if (response) {
      csvCallback({rows: response, columns: classesCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    if (orgGuid) {
      fetchClasses();
    }
  }, [orgGuid, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setPage(1);
      setSearchText('');
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='classes-container'>
      <div className='classes-header'>
        <SearchSubmit 
          placeHolder='Search Class Name or Teacher'
          className='classes-search-bar'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Classes', orgName)}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchClassesForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={classColumnDetails}
        tableData={classesPagedData.values}
        emptyTableContent={<SearchHelpMessage />}
        tableHeight='calc(100vh - 178px)'
        includePagination={true}
        totalItemsCount={classesPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='classes-table'
      />
    </div>
  );
};

export default ClassList;
