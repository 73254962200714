import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@awesome.me/kit-cae4660dd3/icons/classic/solid';
import moment from 'moment';
import store from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { PagedNavTable } from '@wne/mpng-components';
import { OrgService } from '../../fetch-services/org.service';
import { AcademicSession } from '../../fetch-services/org.service.types';
import { AcademicSessionsProps } from './academic-sessions.props';
import { AcademicSessionsReadonlyMessage, AcademicSessionsResetNote } from '../../common/consts';
import './academic-sessions.scss';

const AcademicSessions = (props: AcademicSessionsProps) => {
  const { orgGuid } = props;
  const [academicSessionsData, setAcademicSessionsData] = React.useState<AcademicSession[]>([]);
  
  let districtColumnDetails =
  [
    {
      name: 'Academic Session',
      getColumnData: (data: AcademicSession) => data.title,
      alignment: 'left'
    },
    {
      name: 'School Year',
      getColumnData: (data: AcademicSession) => data.schoolYear,
      alignment: 'left'
    },
    {
      name: 'Start Date',
      getColumnData: (data: AcademicSession) => moment(data.startDate).format('LL'),
      alignment: 'left'
    },
    {
      name: 'End Date',
      getColumnData: (data: AcademicSession) => moment(data.endDate).format('LL'),
      alignment: 'left'
    }
  ];

  useEffect(() => {
    const fetchAcademicSessions = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getAcademicSessions(orgGuid);
      if (response) {
        setAcademicSessionsData(response);
      }
      store.dispatch(setActivityIndicator(false));
    };

    fetchAcademicSessions();
  }, [orgGuid]);

  return (
    <div className='academic-sessions-container'>
      <div className={"academic-note"}>
        <b style={{ fontStyle: "italic" }}>Note:</b>{" "}
        {AcademicSessionsReadonlyMessage}
      </div>
      <div className="academic-clock-bar">
        <FontAwesomeIcon className="academic-sessions-clock-icon" icon={faClock} />
        <span className="academic-clock-bar-text-schedule">{AcademicSessionsResetNote}</span>
      </div>
      <PagedNavTable
        columnDetails={districtColumnDetails}
        tableData={academicSessionsData}
        emptyTableContent='No academic sessions found'
        maxTableHeight='calc(100vh - 128px)'
        className='academic-sessions-table'
      />
    </div>
  );
};

export default AcademicSessions;
