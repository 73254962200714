import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import AcademicSessions from '../../components/academic-sessions/academic-sessions';

//Loads the Academic Sessions component from the sidebar, always uses mainOrg
const AcademicSessionsContainer = () => {
  const { mainOrg } = useSelector((state: RootState) => state.user);
  
  useEffect(() => {
    store.dispatch(setPageTitle('Academic Sessions'));
  }, []);

  return (
    <AcademicSessions orgGuid={mainOrg?.orgGuid ?? ''} />
  );
};

export default AcademicSessionsContainer;
