import { getAccessToken } from './common/get-access-token';
import { Class } from './group.service.types';
import { PendoUserData, Student, Educator, EducatorCSV, Paged, UserInfo, StudentSearchByDistrictRequest, EducatorSearchByDistrictRequest, StudentSearchBySchoolRequest, EducatorSearchBySchoolRequest, StudentCSV, StudentClassesRequest } from './user.service.types';

const userBaseUrl = window.APP_ENV.userApiUrl;

export const UserService = {
  getUserInfo: async (userGuid: string): Promise<UserInfo | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(`${userBaseUrl}/v2/user/${userGuid}`, {
      headers
    });

    if (!response.ok) {
      console.error(
        `UserService.getUserInfo for ${userGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as UserInfo;
  },
  getPendoUserData: async (userGuid: string): Promise<PendoUserData | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(
      `${userBaseUrl}/api/geteducatorpendometadatabyguid?userguId=${userGuid}`,
      {
        headers
      }
    );

    if (!response.ok) {
      console.error(
        `UserService.getPendoUserData for ${userGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as PendoUserData;
  },
  getStudent: async (userGuid: string): Promise<Student | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(`${userBaseUrl}/student/detailswithguid/${userGuid}`, {
      headers
    });

    if (!response.ok) {
      console.error(
        `UserService.getStudentDetails for ${userGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Student;
  },
  getStudentClasses: async (request: StudentClassesRequest): Promise<Class[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/student/enrolledclasses`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getStudentClasses for ${request.studentGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Class[];
  },
  getStudentsByDistrict: async (request: StudentSearchByDistrictRequest): Promise<Paged<Student> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/v2/user/students/bydistrict`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getStudentsByDistrict for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Paged<Student>;
  },
  getStudentsByDistrictCSV: async (request: StudentSearchByDistrictRequest): Promise<Student[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/v2/user/students/bydistrict-csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getStudentsByDistrict for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Student[];
  },
  getStudentsBySchool: async (request: StudentSearchBySchoolRequest): Promise<Paged<Student> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/api/user/getstudentlistwithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getStudentsBySchool for ${request.organizationGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Paged<Student>;
  },
  getStudentsBySchoolCSV: async (request: StudentSearchBySchoolRequest): Promise<StudentCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/school/students/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getStudentsByDistrict for ${request.organizationGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as StudentCSV[];
  },
  getEducatorsByDistrict: async (request: EducatorSearchByDistrictRequest): Promise<Paged<Educator> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/api/user/searcheducatorswithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getEducatorsByDistrict for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Paged<Educator>;
  },
  getEducatorsByDistrictCSV: async (request: EducatorSearchByDistrictRequest): Promise<EducatorCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/users/educators/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getEducatorsByDistrictCSV for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as EducatorCSV[];
  },
  getEducatorsBySchool: async (request: EducatorSearchBySchoolRequest): Promise<Paged<Educator> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/api/user/geteducatorswithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getEducatorsByDistrict for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Paged<Educator>;
  },
  getEducatorsBySchoolCSV: async (request: EducatorSearchBySchoolRequest): Promise<EducatorCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${userBaseUrl}/school/educators/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `UserService.getEducatorsByDistrictCSV for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as EducatorCSV[];
  }
};
