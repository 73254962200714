import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux-store/store';
import { logout } from '../../auth-service/auth-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@awesome.me/kit-cae4660dd3/icons/classic/solid';
import './header.scss';
import { UserInfo } from '../../fetch-services/user.service.types';

const Header = () => {
  const navigate = useNavigate();
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showSiteCode, setShowSiteCode] = useState(false);
  const [user, setUser] = useState<UserInfo | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { pageTitle } = useSelector((state: RootState) => state.app);

  const dropdownRef = createRef<HTMLDivElement>();
  const { firstName, lastName, loginCode, organizations } = user || {};
  let orgName = organizations ? organizations[0].name : '';
  if ((organizations?.length || 0) > 1) {
    orgName = organizations![0].parentOrganization.name;
  }

  const userDisplayName = `${firstName}, ${lastName}`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowUserOptions(false);
      }
    };

    if (showUserOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showUserOptions, dropdownRef]);

  useEffect(() => {
    setUser(userInfo);
    setTitle(pageTitle);
  }, [userInfo, pageTitle]);

  const handleLogout = () => {
    setShowUserOptions(false);
    localStorage.clear();
    logout();
    window.location.href = `${window.APP_ENV.ulpRedirectUrl}`;
  };

  const handleProfileClick = () => {
    setShowUserOptions(false);
    navigate('/profile');
  };

  const renderDotSeparator = () => {
    return (
      <div
        style={{
          width: '4px',
          height: '4px',
          background: '#CECFD0',
          borderRadius: '70px',
          margin: '0 10px 0 10px'
        }}
      />
    );
  };

  return (
    <div className="header-container">
      <div className="header-title">{title}</div>
      <div className="header-user-info">
        <button
          className="site-code-link"
          onClick={() => setShowSiteCode(!showSiteCode)}
        >
          View Site Code
          {showSiteCode && ` - ${loginCode}`}
        </button>
        {renderDotSeparator()}
        <div className="header-org-name">{orgName}</div>
        {renderDotSeparator()}
        <div className="header-user-options">
          <button
            onClick={() => setShowUserOptions(!showUserOptions)}
            className="header-user-name"
          >
            {userDisplayName}
            <FontAwesomeIcon className="dropdown-icon" icon={faSortDown} />
          </button>
          {showUserOptions && (
            <div className="header-user-options-dropdown" ref={dropdownRef}>
              <button className="dropdown-option" onClick={handleProfileClick}>
                Profile
              </button>
              <button className="dropdown-option" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
