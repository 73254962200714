import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { useParams } from 'react-router-dom';
import { setPageTitle, setActivityIndicator } from '../../redux-store/slices/app-slice';
import { OrgGroupLevel } from '../../common/enums';
import { Organization } from '../../fetch-services/org.service.types';
import { OrgService } from '../../fetch-services/org.service';
import { Tabs } from '@wne/mpng-components';
import ClassList from '../../components/class-list/class-list';
import Students from '../../components/students/students';
import Educators from '../../components/educators/educators';
import Reports from '../../components/reports/reports';
import AcademicSessions from '../../components/academic-sessions/academic-sessions';
import OrgSettings from '../../components/org-settings/org-settings';

const School = () => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const [org, setOrg] = useState<Organization | null>(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchOrg = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getOrganization(id ?? '');
      store.dispatch(setActivityIndicator(false));
      if (response) {
        setOrg(response);
      }
      store.dispatch(setPageTitle(response?.name ?? '-'));
    }

    if (id) {
      fetchOrg();
    } else {
      store.dispatch(setPageTitle('-'));
    }
  }, [id]);
  
  const orgDetails = {
    orgLevel: org?.orgType.toLowerCase() as OrgGroupLevel ?? '',
    orgType: org?.orgType ?? '',
    state: org?.state ?? '',
    orgName: org?.name ?? '',
    orgGuid: org?.orgGuid ?? '',
    userGuid: userInfo?.userGuid ?? ''
  }

  const orgGroupDetails = {
    orgGroupLevel: orgDetails.orgLevel,
    orgGroupName: orgDetails.orgName,
    orgGroupGuid: orgDetails.orgGuid
  }

  const tabsProps = {
    className: 'asmt-school-tabs',
    defaultActiveKey: 'classes',
    mountOnEnter: true,
    items: [
      {
        title: 'Classes',
        eventKey: 'classes',
        content: <ClassList {...orgDetails} />
      },
      {
        title: 'Students',
        eventKey: 'students',
        content: <Students {...orgGroupDetails} />
      },
      {
        title: 'Educators',
        eventKey: 'educators',
        content: <Educators {...orgDetails} />
      },
      {
        title: 'Reports',
        eventKey: 'reports',
        content: <Reports {...orgGroupDetails} />
      },
      {
        title: 'Academic Sessions',
        eventKey: 'academic-sessions',
        content: <AcademicSessions {...orgDetails} />
      },
      {
        title: 'Settings',
        eventKey: 'settings',
        content: <OrgSettings {...orgDetails} />
      }
    ]
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default School;
