import React from 'react';
import {
  ProductSelector,
  Navbar,
  Action,
  IconEnum,
  IconLibraryEnum,
  SizeEnum,
  PositionEnum,
  DirectionEnum,
  OrientationEnum
} from '@wne/mpng-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setActiveNavItem } from '../../redux-store/slices/app-slice';
import { Role } from '../../common/enums';
import { SidebarRoutesWithGuid } from '../../common/consts';
import { SidebarItem, SidebarItems } from '../../common/sidebar-item';
import './sidebar.scss';

const Sidebar = () => {
  const navigate = useNavigate();
  const { userInfo, mainOrg } = useSelector((state: RootState) => state.user);
  const { activeNavItem } = useSelector((state: RootState) => state.app);

  const getIconProps = (icon: IconEnum, library: IconLibraryEnum) => ({
    icon,
    size: SizeEnum.MEDIUM,
    textPosition: PositionEnum.BOTTOM,
    squared: true,
    textClassName: 'sidebar-icon-text',
    enhancedClassName: 'sidebar-icon-container',
    library
  });

  const createRouteNavAction = (item: SidebarItem) => {
    const isSelected = activeNavItem === item.label;
    let navIcon = item.icon;
    let navIconLibrary = item.iconLibrary
      ? item.iconLibrary
      : IconLibraryEnum.REGULAR;
    if (isSelected) {
      navIcon = item.selectedIcon ? item.selectedIcon : item.icon;
      navIconLibrary = IconLibraryEnum.SOLID;
    }
    return {
      item: (
        <Action
          callback={() => {
            const addGuid = mainOrg && SidebarRoutesWithGuid.includes(item.link);
            const path = addGuid ? `${item.link}/${mainOrg.orgGuid}` : item.link
            store.dispatch(setActiveNavItem(item.label));
            navigate(path);
          }}
          enabled={true}
          text={item.label}
          className={`sidebar-action route ${isSelected ? 'selected' : ''}`}
          icon={getIconProps(navIcon, navIconLibrary)}
        />
      ),
      direction: DirectionEnum.START
    };
  };

  const sidebarDisplayItems = {
    productSelector: {
      item: (
        //Temporary onclick for ease of navigation until product selector is implemented
        <div onClick={() => window.open(window.APP_ENV.pssBaseUrl, '_self')} className="product-selector-container">
          <ProductSelector />
        </div>
      ),
      direction: DirectionEnum.START
    },
    help: {
      item: (
        <Action
          callback={() => {
            /*open help*/
          }}
          enabled={true}
          text="Help"
          className={'sidebar-action'}
          icon={getIconProps(IconEnum.CIRCLE_QUESTION, IconLibraryEnum.SOLID)}
        />
      ),
      direction: DirectionEnum.END
    }
  };

  const roleSidebarItems = [
    sidebarDisplayItems.productSelector,
    createRouteNavAction(SidebarItems.home)
  ];

  if ((userInfo?.organizations.length || 0) > 1) {
    roleSidebarItems.push(createRouteNavAction(SidebarItems.institutions));
  }

  if (userInfo?.role === Role.DistrictAdmin) {
    roleSidebarItems.push(
      createRouteNavAction(SidebarItems.district),
      createRouteNavAction(SidebarItems.users),
      createRouteNavAction(SidebarItems.scheduling),
      createRouteNavAction(SidebarItems.formatives),
      createRouteNavAction(SidebarItems.reports)
    );
  }

  if (userInfo?.role === Role.SchoolAdmin) {
    roleSidebarItems.push(
      createRouteNavAction(SidebarItems.classes),
      createRouteNavAction(SidebarItems.users),
      createRouteNavAction(SidebarItems.scheduling),
      createRouteNavAction(SidebarItems.academicSessions),
      createRouteNavAction(SidebarItems.settings),
      createRouteNavAction(SidebarItems.formatives),
      createRouteNavAction(SidebarItems.reports)
    );
  }

  if (userInfo?.role === Role.Teacher) {
    roleSidebarItems.push(
      createRouteNavAction(SidebarItems.classes),
      createRouteNavAction(SidebarItems.scheduling),
      createRouteNavAction(SidebarItems.students),
      createRouteNavAction(SidebarItems.formatives)
    );
  }

  roleSidebarItems.push(sidebarDisplayItems.help);

  return (
    <Navbar
      items={roleSidebarItems}
      orientation={OrientationEnum.VERTICAL}
      fixed={PositionEnum.LEFT}
      className="iplus-assessment-sidebar"
      sectionsClassName="sidebar-sections"
      gap="2px"
    />
  );
};
export default Sidebar;
