import React, { useEffect } from 'react';
import store from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { ClassCardsProps } from './class-cards.props';
import { Class, ClassEducator, ClassesSearchRequest } from '../../fetch-services/group.service.types';
import { UserService } from '../../fetch-services/user.service';
import { GroupService } from '../../fetch-services/group.service';
import { useNavigate } from 'react-router-dom';
import { StudentClassesRequest } from '../../fetch-services/user.service.types';
import { Products } from '../../common/enums';
import { ClassCard } from '@wne/mpng-components';
import './class-cards.scss';
import { getEducatorDisplayNames } from '../../common/utils';

const ClassCards = (props: ClassCardsProps) => {
  const { orgGuid, studentGuid } = props;
  const [classes, setClasses] = React.useState<Class[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      store.dispatch(setActivityIndicator(true));
      let classes: Class[] = [];
      if (studentGuid) {
        const response = await UserService.getStudentClasses({
          organizationGuid: orgGuid ?? '',
          studentGuid: studentGuid
        } as StudentClassesRequest);
        if (response) {
          classes = response;
        }
      } else {
        const response = await GroupService.getClasses({
          organizationGuid: orgGuid ?? '',
          page: 1,
          pageSize: 0,
          products: [Products.IPA]
        } as ClassesSearchRequest);
        if (response) {
          classes = response.values;
        }
      }
      setClasses(classes);
      store.dispatch(setActivityIndicator(false));
    };

    if (orgGuid) {
      fetchData();
    }
  }, [orgGuid, studentGuid]);

  return (
    <div className='class-cards-container'>
      {classes.map((classData, index) => (
        <div key={classData.classGUID}>
          {index > 1 && <hr className='card-separator' data-testid='card-separator' />}
          <ClassCard
            key={classData.classGUID}
            title={classData.classTitle}
            teachers={getEducatorDisplayNames(classData.educators)}
            onClick={() => {
              navigate(`/class/${classData.classGUID}`);
            }}
            mpngStyle={false}
          />
        </div>
      ))}
    </div>
  );
};

export default ClassCards;
