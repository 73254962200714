import React from 'react';
import { SettingsProps } from './settings.props';
import './settings.scss';

const Settings = (props: SettingsProps) => {
  const { settings, title } = props;

  return (
    <div className='settings-container'>
      {title && <h1>{title}</h1>}
      {settings.map((setting) => {
        return (
          <div className='setting-item' key={setting.setting}>
            <h2>{setting.setting.toUpperCase()}</h2>
            {setting.values.map((value) => <p key={value}>{value}</p>)}
          </div>
        );
      })}
    </div>
  );
};

export default Settings;