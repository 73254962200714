import { getAccessToken } from './common/get-access-token';
import { AcademicSession, GetChildOrganizationsRequest, Organization, OrganizationSettings } from './org.service.types';
import { Paged } from './user.service.types';

const orgBaseUrl = window.APP_ENV.adminApiUrl;

export const OrgService = {
  getOrganization: async (orgGuid: string): Promise<Organization | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(`${orgBaseUrl}/v2/organization/${orgGuid}`, {
      headers
    });

    if (!response.ok) {
      console.error(
        `OrgService.getOrganization for ${orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Organization;
  },
  getChildOrganizations: async (request: GetChildOrganizationsRequest): Promise<Paged<Organization> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${orgBaseUrl}/v2/organization/childorganizations`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `OrgService.getChildOrganizations for ${request.orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Paged<Organization>;
  },
  getAcademicSessions: async (orgGuid: string): Promise<AcademicSession[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(`${orgBaseUrl}/academicsessionswithguid?organizationGuid=${orgGuid}`, {
      headers
    });

    if (!response.ok) {
      console.error(
        `OrgService.getAcademicSessions for ${orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as AcademicSession[];
  },
  getSettings: async (orgGuid: string): Promise<OrganizationSettings | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(`${orgBaseUrl}/v2/organization/settings/${orgGuid}`, {
      headers
    });

    if (!response.ok) {
      console.error(
        `OrgService.getSettings for ${orgGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as OrganizationSettings;
  }
};
