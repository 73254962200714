import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { SearchSubmit, PagedNavTable, MpngExportReport, SearchHelpMessage } from '@wne/mpng-components';
import { UserService } from '../../fetch-services/user.service';
import {
  Paged,
  Educator,
  EducatorSearchByDistrictRequest,
  EducatorSearchBySchoolRequest
} from '../../fetch-services/user.service.types';
import { OrgGroupLevel } from '../../common/enums';
import { getReportFilename } from '../../common/utils';
import { EducatorsProps } from './educators.props';
import './educators.scss';

const emptyPage: Paged<Educator> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const Educators = (props: EducatorsProps) => {
  const { orgGuid, orgLevel, orgName, userGuid } = props;
  const [educatorsPagedData, setEducatorsPagedData] = React.useState<Paged<Educator>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('LastName');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  let educatorColumnDetails =
  [
    {
      name: 'Educator Name',
      getColumnData: (data: Educator) => `${data.lastName}, ${data.firstName}`,
      alignment: 'left',
      sortProperty: 'lastname',
      onLinkSelect: (data: Educator) => navigate(`/educator/${data.userGuid}`)
    },
    {
      name: 'Username/Email',
      getColumnData: (data: Educator) => data.loginName,
      sortProperty: 'loginname',
      alignment: 'left'
    },
    {
      name: 'Institution',
      getColumnData: (data: Educator) => data.organizations.join(', '),
      sortProperty: 'organizations',
      alignment: 'left'
    },
    {
      name: 'External ID',
      getColumnData: (data: Educator) => data.districtAssignedId,
      alignment: 'left'
    },
    {
      name: 'Educator Type',
      getColumnData: (data: Educator) => data.roleName,
      sortProperty: 'rolename',
      alignment: 'left'
    }
  ];

  if (orgLevel === OrgGroupLevel.School) {
    educatorColumnDetails = educatorColumnDetails.filter((column) => column.name !== 'Institution');
  };

  const educatorsCSVColumns = [
    {
      label: "Educator Name",
      id: "educatorName"
    },
    {
      label: "Username/Email",
      id: "userName"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "External ID",
      id: "districtAssignedId"
    },
    {
      label: "Educator Type",
      id: "educatorType"
    }
  ];

  const getEducatorSearchRequest = () => {
    const request = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      orgGuid: orgGuid,
      page: page,
      pageSize: pageSize
    };
    switch (orgLevel) {
      case OrgGroupLevel.District:
        return {
          ...request,
          userGuid: userGuid
        } as EducatorSearchByDistrictRequest;
      case OrgGroupLevel.School:
      default:
        return {
          ...request,
          filterRoleTypes: ''
        } as EducatorSearchBySchoolRequest;
    }
  };

  const fetchEducators = async () => {
    store.dispatch(setActivityIndicator(true));
    let response = null;
    switch (orgLevel) {
      case OrgGroupLevel.District:
        response = await UserService.getEducatorsByDistrict(getEducatorSearchRequest() as EducatorSearchByDistrictRequest);
        break;
      case OrgGroupLevel.School:
      default:
        response = await UserService.getEducatorsBySchool(getEducatorSearchRequest() as EducatorSearchBySchoolRequest);
    }
    if (response) {
      setEducatorsPagedData(response);
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchEducatorsForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    let response = null;
    switch (orgLevel) {
      case OrgGroupLevel.District:
        response = await UserService.getEducatorsByDistrictCSV(getEducatorSearchRequest() as EducatorSearchByDistrictRequest);
        break;
      case OrgGroupLevel.School:
      default:
        response = await UserService.getEducatorsBySchoolCSV(getEducatorSearchRequest() as EducatorSearchBySchoolRequest);
    }
    if (response) {
      csvCallback({rows: response, columns: educatorsCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    fetchEducators();
  }, [orgGuid, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setSearchText('');
      setPage(1);
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='educators-search'>
      <div className='educators-search-header'>
        <SearchSubmit 
          placeHolder='Search Educator Name, Username/Email, External ID'
          className='educators-search-bar'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Educators', orgName)}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchEducatorsForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={educatorColumnDetails}
        tableData={educatorsPagedData.values}
        emptyTableContent={<SearchHelpMessage />}
        tableHeight='calc(100vh - 178px)'
        includePagination={true}
        totalItemsCount={educatorsPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='educators-table'
      />
    </div>
  );
};

export default Educators;
