import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import OrgSettings from '../../components/org-settings/org-settings';

//Loads the OrgSettings component from the sidebar, always uses mainOrg
const OrgSettingsContainer = () => {
  const { mainOrg } = useSelector((state: RootState) => state.user);
  
  useEffect(() => {
    store.dispatch(setPageTitle('Settings'));
  }, []);

  const orgDetails = {
    orgGuid: mainOrg?.orgGuid ?? '',
    orgType: mainOrg?.orgType ?? '',
    state: mainOrg?.state ?? ''
  };

  return (
    <OrgSettings {...orgDetails} />
  );
};

export default OrgSettingsContainer;
