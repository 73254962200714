import { getAccessToken } from './common/get-access-token';
import { Class, ClassesSearchRequest, ClassStudent, StudentsSearchByClassRequest, CSVClass } from './group.service.types';
import { Paged, StudentCSV } from './user.service.types';

const groupBaseUrl = window.APP_ENV.lmsApiUrl;

export const GroupService = {
  getClasses: async (request: ClassesSearchRequest): Promise<Paged<Class> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${groupBaseUrl}/class/getclasslistwithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `GroupService.getClasses for ${request.organizationGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as Paged<Class>;
  },
  getClassesCSV: async (request: ClassesSearchRequest): Promise<CSVClass[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${groupBaseUrl}/school/classes/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(
        `GroupService.getClassesCSV for ${request.organizationGuid} response status: ${response.status}`
      );
      return null;
    }

    const json = await response.json();
    return json as CSVClass[];
  },
  getClass: async (classGuid: string): Promise<Class | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(`${groupBaseUrl}/class/detailswithguid?classGuid=${classGuid}`, {
      headers
    });

    if (!response.ok) {
      console.error(`GroupService.getClass for ${classGuid} response status: ${response.status}`);
      return null;
    }

    const json = await response.json();
    return json as Class;
  },
  getStudentsByClass: async (request: StudentsSearchByClassRequest): Promise<Paged<ClassStudent> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${groupBaseUrl}/class/studentsenrolledwithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(`GroupService.getStudentsByClass for ${request.classGuid} response status: ${response.status}`);
      return null;
    }

    const json = await response.json();
    return json as Paged<ClassStudent>;
  },
  getStudentsByClassCSV: async (request: StudentsSearchByClassRequest): Promise<StudentCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetch(`${groupBaseUrl}/class/students/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      console.error(`GroupService.getStudentsByClassCSV for ${request.classGuid} response status: ${response.status}`);
      return null;
    }

    const json = await response.json();
    return json as StudentCSV[];
  }
};
